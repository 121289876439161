.widget {
    border-radius: 20px 20px 0px 20px;
    background: #191b1a;
    max-width: 351px;
    width: 100%;
    padding: 15px;
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 10;
    transition: all 0.5s ease-in-out;
}

.widget.closed {
    opacity: 0;
    z-index: -9;
}

.widget-wrapper {
    display: flex;
    gap: 15px;
    align-items: center;
}

.widget-image {
    width: 120px;
    height: 97px;
}

.widget-close {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    filter: grayscale(80%);
}

.widget-item .date {
    width: 120px;
    height: 28px;
    border-radius: 10px;
    background: #00ab04;
    color: #ffffff;
    font-family: "Gilroy";
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
}

.widget-item h3 {
    color: #fff;
    font-family: "Gilroy";
    font-size: 18px;
    margin-bottom: 8px;
}

.widget-item p {
    color: #fff; /* Replace #fff with appropriate color value */
    font-size: 18px;
    font-family: "Gilroy";
    margin-bottom: 8px;
}

.widget-item p span {
    color: #00ab04;
}

.widget-item a {
    border-radius: 7px;
    border: 1px solid #fff; /* Replace #fff with appropriate color value */
    font-family: "Gilroy";
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 16px;
    padding: 6px 15px;
    justify-content: center;
    align-items: center;
    color: #fff; /* Replace #fff with appropriate color value */
    text-decoration: none;
    transition: all 0.3s ease-in-out;
}

.widget-item a:hover {
    background-color: #00ab04;
    border-color: #00ab04;
    text-decoration: none;
    color: #fff; /* Replace #fff with appropriate color value */
}

@media (max-width: 420px) {
    .widget {
        right: 5px;
    }
}
