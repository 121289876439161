#header-modal {
    left: 0;
    position: fixed;
    z-index: 10;
    height: 65px;
    width: 100%;
    top: -70px;
    transition: 0.5s;
}

#header-modal.show {
    top: 0px;
}

#header-modal .inner {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    background-color: #F1F1F1;
    padding: 15px 40px;
    display: flex;
    justify-content: space-between;
    backdrop-filter: blur(5px);
}

#header-modal .logo {
    height: 100%;
}

#header-modal nav {
    display: flex;
    height: 100%;
    align-items: center;

}

#header-modal nav a {
    color: #1c1c29;
    /* height: 100%; */
    padding: 0 10px;
    transition: 0.2s;
    line-height: 1.55;
    font-size: 16px;
    font-weight: 600;
}

#header-modal nav a:hover {
    color: #F0641E;
}

#header-modal .left,
#header-modal .right {
    display: flex;
    height: 100%;
    gap: 20px;
    align-items: center;
    align-items: center;
}

#header-modal .phone {
    font-weight: 600;
    font-size: 0.6em;
    text-decoration: none;
}

#header-modal .btn {
    border-radius: 79px;
    font-family: 'Gilroy';
    font-size: 0.65em;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    padding: 10px 35px;
    background: #F0641E;
    color: #fff;
    transition: all 0.5s ease;
    font-weight: 600;
}

#header-modal button:hover {
    color: #333;
    background-color: #ffffff;
    
}

#header-modal .burger-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

#header-modal .stick {
    display: block;
    width: 23px;
    height: 3px;
    background-color: #F0641E;
    margin-bottom: 5px;
    border-radius: 5px;
    transition: 0.5s;
}

#header-modal .stick:nth-child(2) {
    transition: none;
}

#header-modal .stick:last-child {
    margin-bottom: 0;
}

#header-modal .burger-menu {
    display: none;
}

.burger-menu.active .stick:nth-child(1) {
    transform: rotate(40deg) translateY(8px) translateX(3px);
    width: 25px;
}

.burger-menu.active .stick:nth-child(2) {
    opacity: 0;
}

.burger-menu.active .stick:nth-child(3) {
    transform: rotate(-40deg) translateY(-8px) translateX(3px);
    width: 25px;
}

#header-modal .mobile-menu {
    display: none;
    position: absolute;
    top: 65px;
    left: 0;
    width: 100%;
    background-color: #F1F1F1;
}

#header-modal .mobile-menu .mobile-menu-list {
    list-style-type: none;
    margin: 0 auto;
    width: fit-content;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

#header-modal .mobile-menu .mobile-menu-list li a {
    font-weight: 600;
    text-decoration: none;
}

#header-modal .mobile-menu .mobile-menu-btn {
    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color:  #FFF;
    font-family: 'Gilroy';
    font-size: 12px;
    font-weight: 400;
    border-radius: 79px;
    background: #F0641E;
    margin: 16px auto 16px;
}

#header-modal .mobile-menu .mobile-menu-phone {
    font-size: 16px;
    text-decoration: none;
    font-weight: 600;
    width: fit-content;
    display: block;
    margin: 0 auto 40px;
}


@media (max-width: 992px) {
    #header-modal {
        top: 0;
    }
}

@media screen and (max-width: 1058px) {

    #header-modal .left,
    #header-modal .right {
        gap: 10px;
    }
}

@media screen and (max-width: 901px) {
    #header-modal nav {
        display: none;
    }

    #header-modal .burger-menu {
        display: flex;
    }

    #header-modal .mobile-menu {
        display: block;
    }
}

@media screen and (max-width: 728px) {
    #header-modal .phone {
        display: none;
    }

    #header-modal button {
        padding: 0 10px;
        font-size: 1.3em;
    }

    #header-modal .inner {
        width: calc(100% - 40px);
    }
}

@media (max-width:576px) {
    #header-modal .inner {
        padding: 15px;
        width: 100%;
    }

    #header-modal .btn {
        font-size: 12px;
        display: none;
    }
}

@media (min-width: 1920px) {
    #header-modal {
        height: 3.4vw;
        top: -3.4vw;
    }

    #header-modal .left, #header-modal .right {
        gap: 2.825vw;
    }

    #header-modal .inner {
        padding: 0.833vw 2.125vw;
    }

    #header-modal nav a {
        font-size: 0.825vw;
    }

    #header-modal .btn {
        padding: 0.625vw 2vw;
    }
}