body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #f1f1f1;
    font-size: calc(4px + 1vw);
    color: #333;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

@font-face {
    font-family: "Codec Pro";
    src: url("./fonts/CodecPro-News.ttf");
}

@font-face {
    font-family: "Gilroy";
    src: url("./fonts/Gilroy-Regular.ttf");
}

@font-face {
    font-family: "Codec Pro bold";
    src: url("./fonts/CodecPro-ExtraBold.ttf");
}

@font-face {
    font-family: "Gilroy bold";
    src: url("./fonts/Gilroy-Bold.ttf");
}

h1,
h2 {
    font-family: "Codec Pro";
}

section {
    padding: 100px 40px;
    /* overflow-x: hidden; */
    /* overflow-y: visible; */
}

* {
    box-sizing: border-box;
    font-size: 1em;
    margin: 0;
    padding: 0;
    font-family: "Gilroy";
}

nav {
    display: flex;
    gap: 30px;
}

button {
    cursor: pointer;
    border: none;
    outline: none;
}

a {
    cursor: pointer;
}

nav button {
    background-color: transparent;
}

h1 {
    font-size: 2.5em;
}

h2,
h2.primary {
    font-size: 2em;
    color: var(--unnamed, #333);
    font-family: "Gilroy";
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    padding-bottom: 10px;
}

h3 {
    font-size: 1.5em;
}

p {
    padding-bottom: 10px;
}

button,
a,
span,
p {
    font-size: 0.8em;
    color: var(--unnamed, #333);
    font-family: "Gilroy";
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

button.primary {
    height: 50px;
    padding: 15px 36px;
    background-color: #00705a;
    color: white;
    border-radius: 79px;
    background: var(--2, #00705a);
    line-height: 0.95;
    color: white !important;
    transition: all 0.4s ease-in-out;
}

button.primary:hover {
    background-color: #f0641e;
}

.orange {
    color: #f0641e !important;
}

@media (min-width: 1921px) {
    section {
        padding: 5.225vw 2.084vw;
    }

    nav {
        gap: 1.5725vw;
    }

    button.primary {
        height: unset;
        padding: 0.825vw 1.825vw;
    }
}

@media (max-width: 768px) {
    section {
        padding: 50px 20px;
    }
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: unset;
    background-color: transparent;
}

::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
    background-color: #f0641e;
    border-radius: 10px;
}
