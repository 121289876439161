#advantages .inner {
    display: flex;
    gap: 20px;
    row-gap: 20px;
}

#advantages .left {
    display: flex;
    gap: 20px;
    row-gap: 20px;
    width: 50%;
}

#advantages .left .column {
    display: flex;
    width: 50%;
    flex-direction: column;
    row-gap: 20px;
    flex: 0 0 50%;
}

#advantages .column .item {
    background-color: #00705a;
    height: 350px;
    position: relative;
    overflow: hidden;
}

#advantages .left .item {
    border-radius: 20px 20px 150px 20px;
    background-color: #f0641e;
    overflow: hidden;
    position: relative;
}

#advantages .left .item img {
    position: absolute;
    bottom: -15%;
    left: 50%;
    width: 120%;
    transform: translateX(-50%);
}

#advantages .column .item h2 {
    color: var(--, #fff);
    font-family: "Gilroy";
    font-size: 32px;
    font-weight: 600;
    position: relative;
    z-index: 5;
}

#advantages .column .item:first-child {
    border-radius: 20px 20px 150px 20px;
    background-color: #a0d773;
}

#advantages .column .item:first-child img {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 80%;
}

#advantages .column .item:last-child {
    border-radius: 150px 20px 20px 20px;
    background-color: #00705a;
    justify-content: end;
    align-items: end;
    position: relative;
}

#advantages .column .item:last-child h2 {
    text-align: end;
}

#advantages .column .item:last-child img {
    position: absolute;
    bottom: 0;
    left: 15%;
    width: 60%;
    z-index: 0;
}

#advantages .right .row .item:first-child {
    border-radius: 20px 20px 20px 150px;
    background-color: #00705a;
}
#advantages .right .row .item:last-child {
    border-radius: 150px 20px 20px 20px;
    background-color: #fff;
    justify-content: end;
    align-items: end;
}

#advantages .right .row .item4 {
    position: relative;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
    flex: 0 0 50%;
}

#advantages .right .row .item4 h2,
#advantages .right .row .item4 p {
    z-index: 2;
    text-align: start;
}

#advantages .right .row .item4 img {
    position: absolute;
    z-index: 0;
    bottom: 0;
    right: -2vw;
    width: 70%;
}

#advantages .right .row .item5 {
    position: relative;
    overflow: hidden;
}

#advantages .right .row .item5 .overlay {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 60%;
    z-index: 1;
    background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(255, 255, 255, 0.85) 0%,
        rgba(255, 255, 255, 0) 100%
    );
}

#advantages .right .row .item5 h2 {
    z-index: 2;
}

#advantages .right .row .item5 img {
    position: absolute;
    z-index: 0;
    bottom: 0;
    right: 0;
    width: 65%;
}

#advantages .right .item6 {
    position: relative;
}

#advantages .right .item6 h2 {
    z-index: 2;
}

#advantages .right .item6 img {
    position: absolute;
    right: 10%;
    bottom: 0;
    height: 90%;
}

#advantages .right .row .item:last-child h2 {
    color: #333;
    text-align: right;
    font-family: "Gilroy";
    font-size: 32px;
    font-weight: 600;
}

#advantages .right .row .item:first-child h2 {
    color: #a0d773;
    font-family: "Gilroy";
    font-size: 32px;
    font-weight: 600;
}

#advantages .right {
    display: flex;
    flex-direction: column;
    gap: 20px;
    row-gap: 20px;
    width: 50%;
}

#advantages .right .item {
    border-radius: 20px 20px 20px 150px;
    background: #333;
}

#advantages .row {
    display: flex;
    width: 100%;
    gap: 20px;
}

#advantages .row .item {
    height: 350px;
}

#advantages h2 {
    color: white;
    font-family: "Gilroy";
    font-size: 1.3em;
    font-style: normal;
    font-weight: 600;
}

#advantages p {
    font-size: 16px;
    color: white;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    position: relative;
    z-index: 2;
}

#advantages .item {
    padding: 30px;
    background-repeat: no-repeat !important;
    background-position: 10% 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    height: 100%;
}

#advantages .item > p {
    max-width: 275px;
}

#advantages .item1 {
    border-radius: 20px 20px 150px 20px;
    background: #a0d773;
    background-image: url("../../images/adv1.png");
    background-position: 50% calc(200%);
    background-size: 85%;
}

#advantages .item4 {
    border-radius: 150px 20px 20px 20px;
    background: var(--2, #00705a);
    /* height: 100%; */
    background-image: url("../../images/adv2.png");
    background-position: -10% 100%;
    background-size: 70%;
    justify-content: flex-end;
    align-items: flex-end;
}

#advantages .item3 {
    border-radius: 20px 20px 150px 20px;
    background: var(--unnamed, #f0641e);
    background-image: url("../../images/adv3.png");
    background-size: 120%;
    background-position: 50% 160%;
}

#advantages .item2 {
    border-radius: 20px 20px 20px 150px;
    background: var(--2, #00705a);
    background-image: url("../../images/adv4.png");
    background-position: 80% 100%;
}

#advantages .item4 h2 {
    text-align: right;
}

#advantages .item5 {
    border-radius: 150px 20px 20px 20px;
    background-color: #fff;
    background-image: url("../../images/adv5.png");
    background-size: cover;
    background-position: 80% calc(100% + 50px);
    justify-content: flex-end;
    align-items: flex-end;
}

#advantages .item5 h2,
#advantages .item5 p {
    color: #333;
    text-align: right;
}

#advantages .item6 {
    border-radius: 20px 20px 20px 150px;
    background: var(--unnamed, #333);
    background-image: url("../../images/adv6.png");
    background-size: 32%;
    background-position: 90% 100%;
}

#advantages table.short {
    display: none;
}

table {
    width: 100%;
    border-collapse: collapse;
}

td {
    width: 25%;
    height: 0;
    padding-bottom: 25%;
    position: relative;
    border: 1px solid transparent;
}

tr {
    width: 100%;
}

.cell2 {
    height: 0;
    padding-bottom: 50%;
}

.cell5 {
    width: 50%;
}

.content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 20px;
}

@media (min-width: 1921px) {
    #advantages .inner {
        margin-bottom: 4vw;
    }

    #advantages .inner,
    #advantages .left,
    #advantages .row,
    #advantages .right {
        gap: 2vw;
    }

    #advantages .left .column {
        gap: 2vw;
    }

    #advantages .item {
        padding: 1.8vw;
    }

    #advantages .column .item {
        height: 18vw;
    }

    #advantages .item > p {
        max-width: 14.4vw;
    }

    #advantages .column .item:first-child {
        border-radius: 1vw 1vw 8vw 1vw;
    }

    #advantages h2 {
        font-size: 1.7vw !important;
        padding-bottom: 0.5vw;
    }

    #advantages p {
        font-size: 0.85vw !important;
    }

    #advantages .column .item:last-child {
        border-radius: 8vw 1vw 1vw 1vw;
    }

    #advantages .row .item {
        height: 18vw;
    }

    #advantages .left .item {
        border-radius: 1vw 1vw 8vw 1vw;
    }

    #advantages .right .row .item:first-child {
        border-radius: 1vw 1vw 1vw 8vw;
    }

    #advantages .right .row .item:last-child {
        border-radius: 8vw 1vw 1vw 1vw;
    }

    #advantages .right .item6 {
        border-radius: 1vw 1vw 1vw 8vw;
    }
}

@media (max-width: 1440px) {
    #advantages .inner,
    #advantages .left,
    #advantages .right,
    #advantages .row,
    #advantages .left .column {
        gap: 20px;
    }

    #advantages .column .item,
    #advantages .row .item,
    #advantages .right .item6 {
        height: 300px;
    }
}
@media (max-width: 1240px) {
    #advantages .inner,
    #advantages .left,
    #advantages .right,
    #advantages .row,
    #advantages .left .column {
        gap: 20px;
    }

    #advantages .column .item,
    #advantages .row .item,
    #advantages .right .item6 {
        height: 250px;
    }

    #advantages h2 {
        font-size: 24px !important;
    }
}

@media (max-width: 992px) {
    #advantages .inner {
        flex-wrap: wrap;
    }

    #advantages .left,
    #advantages .right {
        width: 100%;
    }
}

@media screen and (max-width: 960px) {
    #advantages tr {
        height: 280px;
    }
    #advantages table {
        display: none;
    }
    #advantages table.short {
        display: table;
    }

    #advantages .item1 {
        background-position: 50% bottom;
        background-size: 70%;
    }
    #advantages .item3 {
        background-position-y: bottom;
    }
    #advantages .item4 {
        background-position: 0% 20%;
    }
    #advantages .item5 {
        background-position: 0% 0%;
    }
    #advantages .item6 {
        background-position: 100% 20%;
    }

    td {
        width: 50%;
        /* height: 0; */
        padding-bottom: 25%;
        position: relative;
        /* border: 20px solid transparent; */
    }

    .cell2 {
        height: 0;
        padding-bottom: 50%;
    }

    .cell5 {
        width: 100%;
    }
}

@media (max-width: 576px) {
    #advantages h2 {
        font-size: 20px;
    }

    #advantages .item {
        padding: 20px;
        background-position-y: bottom;
    }

    #advantages .short td {
        border: 5px solid transparent;
    }

    #advantages p {
        font-size: 14px;
    }

    #advantages .inner,
    #advantages .left,
    #advantages .right,
    #advantages .row,
    #advantages .left .column {
        gap: 10px;
    }

    #advantages p {
        display: none;
    }
}

@media (max-width: 420px) {
    #advantages .column .item,
    #advantages .row .item,
    #advantages .right .item6 {
        height: 200px;
    }

    #advantages .item {
        border-radius: 20px !important;
    }
}
