footer .inner {
    padding: 50px 40px;
    background: #fff;
    margin-top: 100px;
}

footer .container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
}

footer .top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
}

footer .logo {
    width: 60%;
}

footer .addresses {
    width: 100%;
    display: flex;
    font-size: 0.8em;
    /* gap: 50px; */
    justify-content: space-between;
    line-height: 150%;
    letter-spacing: -0.16px;
    color: var(--8, #f5f5f5);
}

footer .addresses a {
    font-size: 14px;
    text-decoration: none;
}

footer .addresses .phone {
    color: #f0641e;
    font-family: "Codec Pro";
    font-size: 0.833vw;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: -0.32px;
}

footer .block {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

footer .time {
    font-family: "AtypText Thin";
}

/* footer .phone {
    border-radius: 10px;
    background: var(--1, #473886);
    padding: 15px 20px 13px 20px;
} */

footer .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 90px;
    padding-top: 20px;
}

footer .icons {
    display: flex;
    width: 60%;
    justify-content: space-between;
    height: 27%;
}

footer .icons > * {
    width: 24px;
}

footer .bottom p {
    font-size: 0.6em;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #6c6d6d;
    letter-spacing: -0.12px;
    width: 60%;
}

footer .left {
    width: 15%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

footer .left a {
    font-size: 16px;
    text-decoration: none;
    font-weight: 400;
}

footer .right {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

footer p {
    padding: 0;
}

footer h4 {
    color: var(--gray-gray-500, #6c6d6d);
    font-family: "Gilroy";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    /* 18.2px */
}

footer hr {
    opacity: 0.5;
    height: 2px;
    border: none;
    background: #cfd2d1;
}

footer button {
    padding: 15px 36px;
    border-radius: 79px;
    border: 1px solid var(--unnamed, #f0641e);
    background-color: transparent;
    color: #f0641e;
    transition: all 0.5s ease;
}

footer button:hover {
    background-color: #f0641e;
    color: #fff;
}

footer .bottom-links {
    display: flex;
    gap: 1.667vw;
}

footer .bottom-links a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2vw;
    height: 2vw;
}

footer .bottom-links a img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media (min-width: 1920px) {
    footer .inner {
        padding: 2vw 3vw;
    }
    footer h4,
    footer .left a {
        font-size: 1vw;
    }
    footer .block {
        gap: 0.725vw;
    }

    footer button {
        padding: 0.781vw 1.875vw;
    }

    footer .addresses a {
        font-size: 0.825vw;
    }

    footer .container {
        padding-bottom: 2vw;
    }

    footer .bottom {
        padding-top: 1vw;
    }
}

@media screen and (max-width: 997px) {
    footer .container {
        flex-direction: column;
        gap: 30px;
    }

    footer .left {
        align-items: center;
        flex-direction: row;
        width: 100%;
    }

    footer .logo {
        width: 12%;
    }

    footer .icons {
        width: 15%;
    }

    footer .right {
        width: 100%;
    }

    footer .bottom p,
    footer .addresses .phone,
    footer button {
        font-size: 16px;
    }

    footer .addresses {
        justify-content: space-between;
    }
}

@media screen and (max-width: 865px) {
    footer .top {
        flex-direction: column;
        gap: 30px;
    }

    footer .addresses {
        width: 100%;
    }

    footer .addresses .phone {
        font-size: 16px;
    }

    footer button {
        font-size: 16px;
    }
}

@media screen and (max-width: 728px) {
    footer .logo,
    footer .icons {
        width: 25%;
    }

    footer .block {
        width: 100%;
    }

    footer .addresses {
        font-size: 1.2em;
        row-gap: 20px;
        flex-wrap: wrap;
    }

    footer .bottom {
        flex-wrap: wrap;
        gap: 30px;
    }

    footer .bottom p {
        font-size: 16px;
        flex: 0 0 100%;
    }

    footer .bottom-links {
        gap: 20px;
    }

    footer .bottom-links a {
        width: 24px;
        height: 24px;
    }

    footer .phone {
        width: 100%;
        text-align: center;
    }

    footer .container {
        padding: 30px 20px;
    }
}

@media (max-width: 576px) {
    footer button {
        font-size: 14px;
        padding: 10px 24px;
        font-weight: 600;
    }

    footer .block:last-child {
        width: 100%;
    }
    footer .block:last-child h4 {
        display: none;
    }

    footer .block:last-child a {
        text-align: start;
        font-size: 24px;
        font-weight: 600;
        text-align: center;
    }

    footer .bottom p {
        width: 100%;
        font-size: 12px;
    }
}
