#success {
    position: fixed;
    top: 50%;
    width: 100vw;
    height: 100vh;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    justify-content: center;
    align-items: center;
}

#success .success-wrapper {
    padding: 30px;
    text-align: center;
    background-color: #fff;
    max-width: 560px;
    width: 100%;
}

#success h3 {
    margin-bottom: 16px;
    color: #f0641e;
}

#form {
    margin-top: 140px;
    border-radius: 20px;
    border: 2px solid white;
    padding: 68px 94px;
    display: flex;
    overflow: hidden;
    justify-content: flex-end;
    position: relative;
    gap: 10%;
    align-items: center;
}

#form .text {
    width: 30%;
}

#form .captcha > div > div > div {
    width: 100% !important;
    height: fit-content !important;
}

#form h2 {
    font-size: 2em;
    color: var(--unnamed, #333);
    font-family: "Gilroy";
}

#form p {
    color: var(--unnamed, #333);
}

#form form {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    align-items: stretch;
}

#form .svg {
    width: 25%;
    position: absolute;
    top: -40%;
    left: -1%;
}

#form .error {
    position: absolute;
    height: 11px;
    color: rgb(255, 119, 119);
    font-size: 11px;
    opacity: 0;
    transition: 0.5s;
}

#form .error.active {
    opacity: 1;
}

#form .check {
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.16px;
    font-size: 0.7em;
    display: block;
    position: relative;
    padding-left: 26px;
}

#form .check input {
    display: none;
}

#form .check span {
    transition: 0.2s;
    position: absolute;
    /* top: 3px; */
    transform: translateY(25%);
    left: 0;
    height: 16px;
    width: 16px;
    background-color: white;
    border-radius: 4px;
}

#form .check input:checked ~ span {
    background-color: #f0641e;
    border-radius: 3px;
}

#form .check span::after {
    content: "";
    position: absolute;
    display: none;
}

#form .check input:checked ~ span:after {
    display: block;
}

#form .check span:after {
    left: 6px;
    top: 2px;
    width: 3px;
    height: 7px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

#form input {
    outline: none;
    border-radius: 99px;
    border: 1px solid var(--gray-4, #bdbdbd);
    padding: 15px 25px 11px 25px;
    color: var(--gray-3, #828282);
    font-family: "Gilroy";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    background-color: transparent;
    width: 100%;
}

#form button {
    border-radius: 79px;
    background: var(--unnamed, #f0641e);
    color: var(--unnamed, #fff);
    font-family: "Gilroy";
    font-size: 0.75em;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    align-self: flex-start;
    padding: 15px 35px;
    transition: all 0.4s ease-in-out;
}

#form button:hover {
    background-color: #00705a;
}

#form button:disabled {
    color: #fff;
    background-color: #aeaeae;
    border-color: #aeaeae;
    cursor: not-allowed;
}

#form .img {
    position: absolute;
    left: 0;
    width: 30%;
    border-radius: 0 999px 999px 0;
}

@media (min-width: 1920px) {
    #form {
        padding: 4vw 6vw;
    }

    #form input {
        padding: 1vw 2vw 0.85vw;
        font-size: 0.75vw;
    }

    #form form {
        gap: 1vw;
    }

    #form button {
        border-radius: 4vw;
        padding: 0.75vw 2.4vw;
    }
}

@media (max-width: 992px) {
    #success .success-wrapper {
        margin: 0 15px;
    }

    #success h3 {
        margin-bottom: 16px;
        font-size: 24px;
        color: #f0641e;
    }

    #success p {
        font-size: 16px;
    }
}

@media (max-width: 576px) {
    #form .svg,
    #form .img {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        z-index: -1;
    }

    #form .svg {
        display: none;
    }
    #form .text {
        width: 90%;
        text-align: center;
    }
    #form p,
    #form h2 {
        font-weight: 600;
        font-size: 16px;
    }
    #form h2 {
        font-size: 24px;
    }
    #form form {
        width: 90%;
    }
    #form input {
        background-color: #fff;
    }
    #form input::placeholder {
        font-size: 14px;
    }
    #form button {
        width: 100%;
        font-size: 16px;
        font-weight: 600;
    }
    #form {
        margin-top: 80px;
        padding: 30px 40px;
        flex-direction: column;
    }
}

@media (max-width: 576px) {
    #form .svg,
    #form .img {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        z-index: -1;
    }

    #form .svg {
        display: none;
    }
    #form .text {
        width: 90%;
        text-align: center;
    }
    #form p,
    #form h2 {
        font-weight: 600;
        font-size: 16px;
    }
    #form h2 {
        font-size: 24px;
    }
    #form form {
        width: 90%;
    }
    #form input {
        background-color: #fff;
    }
    #form input::placeholder {
        font-size: 14px;
    }
    #form button {
        width: 100%;
        font-size: 16px;
        font-weight: 600;
    }
    #form {
        margin-top: 80px;
        padding: 30px 40px;
        flex-direction: column;
    }
}
