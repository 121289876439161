#progress {
    position: relative;
}

#progress .progress-wrapper {
    display: flex;
}

#progress .progress-wrapper .progress-left {
    flex: 0 0 calc(40% - 20px);
    margin-right: 20px;
}

#progress .progress-wrapper .progress-left h4 {
    color: #f0641e;
    font-family: "Gilroy";
    font-size: 0.75vw;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 11px */
    text-transform: uppercase;
    margin-bottom: 10px;
}

#progress .progress-wrapper .progress-left h2 {
    color: #333;
    font-family: "Gilroy";
    font-size: 2vw;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    text-transform: uppercase;
    margin-bottom: 30px;
}

#progress .progress-wrapper .progress-left p {
    color: #333;
    font-family: "Gilroy";
    font-size: 1.25vw;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 30px;
    min-width: 369px;
    width: 100%;
}

#progress .progress-wrapper .progress-left button {
    padding: 15px 36px;
    border-radius: 79px;
    background: #f0641e;
    color: #fff;
    font-family: "Gilroy";
    font-size: 1.125vw;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    transition: all 0.4s ease-in-out;
}

#progress .progress-wrapper .progress-left button:hover {
    background-color: #00705a;
}

#progress .progress-wrapper .progress-percentage {
    padding: 27px 40px;
    background-color: #fff;
    border-radius: 1vw;
    display: flex;
    flex: 0 0 calc(30% - 20px);
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-right: 20px;
}

#progress .progress-wrapper .progress-percentage h4 {
    color: var(--, #333);
    text-align: center;
    font-family: "Gilroy";
    font-size: 1.35vw;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 18px;
}

#progress .progress-wrapper .progress-video {
    flex: 1 1 45%;
    position: relative;
}

#progress .progress-wrapper .progress-video img {
    width: 100%;
    height: 100%;
    border-radius: 1vw;
    border: unset;
    object-fit: cover;
}

#progress .progress-wrapper .progress-video .playvideo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    width: 64px;
    height: 64px;
    border-radius: 100%;
    padding: 20px;
    cursor: pointer;
}

#progress .modal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
}
#progress .modal .modal-content {
    max-width: 800px;
    width: 100%;
    height: 400px;
}

#progress .modal .modal-content iframe {
    border-radius: 20px;
    max-width: 800px;
    width: 100%;
    height: 100%;
}

@media (min-width: 1920px) {
    #progress .progress-wrapper .progress-left h4 {
        margin-bottom: 0.7vw;
    }
    #progress .progress-wrapper .progress-left h2 {
        margin-bottom: 1.5vw;
        padding-bottom: 0;
    }
    #progress .progress-wrapper .progress-left {
        margin-right: 1.05vw;
    }
    #progress .progress-wrapper .progress-left button {
        padding: 0.825vw 1.925vw;
        border-radius: 7vw;
    }
    #progress .progress-wrapper .progress-percentage {
        margin-right: 1vw;
    }

    #progress .progress-wrapper .progress-left p {
        margin-bottom: 1.25vw;
    }

    #progress .progress-wrapper .progress-video .playvideo {
        width: 3.5vw;
        height: 3.5vw;
        padding: 0.825vw;
    }

    #progress .modal .modal-content {
        max-width: 40vw;
        height: 30vw;
    }

    #progress .modal .modal-content iframe {
        max-width: unset;
        border-radius: 1vw;
    }
}

@media (max-width: 1350px) {
    #progress .progress-wrapper .progress-video {
        flex: 1 0 30%;
    }
}

@media (max-width: 992px) {
    #progress .progress-wrapper {
        flex-wrap: wrap;
    }

    #progress .progress-wrapper .progress-left {
        margin-bottom: 20px;
    }

    #progress .progress-wrapper .progress-left h2 {
        margin-bottom: 16px;
        padding-bottom: 0;
        font-size: 24px;
    }

    #progress .progress-wrapper .progress-left p {
        margin-bottom: 16px;
        padding-bottom: 0;
        min-width: unset;
        font-size: 14px;
    }

    #progress .progress-wrapper .progress-left {
        flex: 0 0 100%;
    }
    #progress .progress-wrapper .progress-left h4 {
        font-size: 16px;
    }

    #progress .progress-wrapper .progress-left button {
        font-size: 14px;
    }

    #progress .progress-wrapper .progress-percentage h4 {
        font-size: 16px;
    }

    #progress .progress-wrapper .progress-percentage,
    #progress .progress-wrapper .progress-video img {
        border-radius: 20px;
    }
}

@media (max-width: 650px) {
    #progress .progress-wrapper .progress-left {
        flex: 0 0 50%;
    }

    #progress .progress-wrapper .progress-percentage {
        flex: 0 0 calc(50% - 20px);
        padding: 20px;
    }

    #progress .progress-wrapper .progress-percentage h4 {
        font-size: 16px;
    }

    #progress .progress-wrapper .progress-video {
        height: 300px;
    }
}

@media (max-width: 559px) {
    #progress .progress-wrapper .progress-percentage {
        flex-basis: 100%;
        margin-bottom: 20px;
    }

    #progress .progress-wrapper .progress-left {
        flex: 0 0 100%;
    }
}
