circle {
    transition: all 0.7s ease-in-out;
}

.progress-percentage svg {
    width: 200px;
    height: 200px;
}

.CircularProgressbar .CircularProgressbar-path {
    stroke: #f0641e;
}

.CircularProgressbar .CircularProgressbar-text {
    fill: #333;
    font-size: 18px;
    font-weight: 600;
}
