#banner {
    padding-top: 0;
    position: relative;
}

#banner .header {
    padding: 30px 0;
    display: flex;
    align-items: center;
}

#banner .logo {
    width: 10%;
    min-width: 200px;
    /* padding-right: 20px; */
}

#banner nav {
    width: 80%;
    margin-left: 75px;
}

#banner nav a {
    font-size: 18px;
    font-weight: 600;
}

#banner .phone {
    flex: 0 0 283px;
    background-color: white;
    position: relative;
    right: -40px;
    height: 44px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 18px;
}

#banner .phone span {
    font-size: 20px;
    font-weight: 600;
}

#banner .phone span span {
    color: #f0641e;
}

#banner .phone-svg {
    width: 10%;
}

#banner button {
    color: var(--unnamed, #333);
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    transition: 0.2s;
    margin-top: 30px;
    padding: 16px 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#banner button:hover {
    color: #f0641e;
}

#banner .carousel {
    width: 40%;
    position: absolute;
    top: 0;
    right: 5%;
    height: 0;
    padding-bottom: 40%;
    border-radius: 0 0 999px 999px;
    overflow: hidden;
    z-index: -1;
}

#banner .img-con {
    width: 100%;
}

#banner .carousel img {
    width: 134%;
    object-fit: cover;
    object-position: center;
}

#banner .headings {
    display: flex;
    flex-direction: column;
    gap: 0.5vw;
    padding-top: 3%;
    align-items: flex-start;
}

#banner h1 {
    color: var(--unnamed, #f0641e);
    font-family: "Codec Pro Bold";
    font-size: calc(4.5vw);
    font-style: normal;
    font-weight: 900;
    line-height: 100%;
    text-transform: uppercase;
}

#banner h2 {
    color: #333;
    font-family: "Codec Pro";
    font-size: 2.5vw;
    padding-bottom: 0;
    margin-bottom: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: -1.62px;
}

#banner h3 {
    color: #00705a;
    font-size: 3.45vw;
    font-family: "Codec Pro";
    line-height: 1.2;
    font-weight: 600;
    letter-spacing: -1px;
}

/* #banner .headings button {
    height: 50px;
    padding: 15px 36px;
    background-color: #00705a;
    color: white;
    border-radius: 79px;
    background: var(--2, #00705A);
    line-height: 0.95;
} */

#banner .bg {
    position: absolute;
    z-index: -1;
    width: 60%;
    top: -43%;
    left: -15%;
}

#banner .bg2 {
    left: 40%;
    top: -30%;
}

#banner .phone-mobile {
    position: absolute;
    top: 90px;
    right: 0;
    display: none;
    justify-content: flex-start;
    align-items: center;
    width: fit-content;
    text-decoration: none;
    color: #828282;
    font-family: "Codec Pro";
    font-size: 20px;
    font-weight: 600;
    line-height: 1;
    background-color: #fff;
    padding: 5px 12px 2px;
    border-radius: 15px 0 0 15px;
}

#banner .phone-mobile svg {
    width: 28px;
    height: 28px;
    margin-right: 8px;
    margin-bottom: 3px;
}

#banner .phone-mobile .grey-span {
    color: #828282;
}

#banner .phone-mobile span {
    font-weight: 600;
    line-height: 1;
    color: #f0641e;
    font-family: "Codec Pro";
    font-size: 20px;
}

@media (min-width: 1921px) {
    #banner nav a {
        font-size: 1vw;
    }

    #banner .phone-svg {
        width: 7%;
    }

    #banner nav {
        margin-left: 4vw;
        gap: 2vw;
    }

    #banner .phone {
        flex: 0 0 17%;
        gap: 0.75vw;
        padding-left: 1vw;
        height: 2.5vw;
        border-radius: 1.125vw 0 0 1.125vw;
        position: absolute;
        right: 0;
    }

    #banner .phone span {
        font-size: 1.125vw;
    }

    #banner .header {
        padding: 1.425vw 0;
    }

    #banner button {
        height: 2.425vw;
        padding: 0.845vw 1.875vw;
    }
}

@media (max-width: 992px) {
    #banner .header {
        display: none;
    }

    #banner .headings {
        padding-top: 20%;
    }

    #banner .carousel {
        top: 65px;
    }
}

@media (max-width: 576px) {
    #banner {
        display: flex;
        flex-direction: column;
    }

    #banner .phone-mobile {
        display: flex;
    }
    #banner .main {
        display: flex;
        order: 2;
    }

    #banner .headings {
        display: flex;
        align-items: center;
        width: 100%;
    }

    #banner .headings h2 {
        font-size: 32px;
        font-family: "Gilroy bold", sans-serif;
        font-weight: 600;
        text-align: center;
        max-width: 340px;
        width: 100%;
    }

    #banner .headings h1 {
        text-align: center;
        font-size: 34px;
    }

    #banner .headings h3 {
        text-align: center;
        font-size: 24px;
        margin-top: 20px;
    }

    #banner button {
        width: 100%;
        font-size: 24px;
        font-weight: 600;
        padding-top: 32px;
        padding-bottom: 32px;
    }

    #banner .carousel {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        padding-bottom: 60%;
        order: 1;
    }
}

@media (max-width: 420px) {
    #banner .carousel {
        padding-bottom: 80%;
    }

    #banner .headings {
        padding-top: 25%;
    }
}
