#all-in-one {
    display: flex;
}

#all-in-one .left-img {
    width: 40%;
    border-radius: 0 100% 100% 0;
    position: relative;
    left: -40px;
}

#all-in-one .buttons {
    display: flex;
    gap: 10px;
}

#all-in-one button {
    width: 3em;
    height: 3em;
    border-radius: 100%;
    background-color: #f0641e;
    transition: 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.4s ease-in-out;
}

#all-in-one button:hover {
    background-color: #00705a;
}

#all-in-one button > * {
    width: 60%;
}

#all-in-one button .right {
    transform: rotate(180deg);
}

#all-in-one .top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 14px;
}

#all-in-one .card {
    display: flex;
    flex: 0 0 30%;
    flex-direction: column;
    justify-content: flex-start;
}

#all-in-one .card-bg {
    width: 100%;
    padding-top: 120%;
    background-size: cover;
    border-radius: 20px;
    height: 100%;
}

#all-in-one .slider {
    position: relative;
    display: flex;
    overflow-x: scroll;
    gap: 20px;
    cursor: grab;
}

#all-in-one .slider::-webkit-scrollbar {
    display: none;
}

#all-in-one .card h4 {
    line-height: 120%;
    color: #ffffff;
    margin: 20px 30px;
}

#all-in-one .card p {
    font-family: "K_Gotham Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #f2f2f2;
    text-transform: none;
    width: 100%;
}

#all-in-one .carousel p {
    padding-bottom: 0;
    margin-bottom: 1.51vw;
    line-height: 140%;
    font-size: 0.833vw;
}

@media (min-width: 1920px) {
    #all-in-one .card-bg {
        border-radius: 1vw;
    }

    #all-in-one .card h4 {
        margin: 1vw 1.25vw;
    }
}

@media (max-width: 992px) {
    #all-in-one .carousel p {
        font-size: 16px;
    }
}

@media (max-width: 768px) {
    #all-in-one .left-img {
        display: none;
    }

    #all-in-one .carousel p {
        font-size: 16px;
        margin-bottom: 30px;
    }

    #all-in-one button {
        width: 40px;
        height: 40px;
    }
}

@media (max-width: 576px) {
    #all-in-one .top h2 {
        font-size: 24px;
    }

    #all-in-one p {
        font-size: 16px;
    }

    #all-in-one .carousel {
        width: 100%;
    }

    #all-in-one .slider {
        gap: 10px;
    }

    #all-in-one .card h4 {
        margin: 16px 32px;
        font-size: 16px;
    }

    #all-in-one .card {
        flex: 0 0 85%;
    }

    #all-in-one .card-bg {
        padding-top: 100%;
    }
}
