.vehicle {
    position: relative;
    overflow: hidden;
    height: 920px;
    display: none;
    margin: 80px 0 60px
}
.vehicle .track{
    position: absolute;
    right: 60px;
    width: 2px;
    height: 800px;
    background-color: #F0641E;
    margin: 0 auto;
}
.vehicle .vehicle-img {
    position: absolute;
    right: 25px;
    top: 0;
    z-index: 2;
}
.vehicle .vehicle-img img {
    width: 160px;
}

.vehicle .circle-item {
    display: flex;
    flex-direction: row-reverse;
    gap: 40px;
}

.vehicle .circle-item-wrapper {
    position: absolute;
    right: 47px;
    display: flex;
    flex-direction: column;
    gap: 80px;
    padding-right: 1px;
}

.vehicle .circle-item .round {
    width: 28px;
    height: 28px;
    border-radius: 100%;
    border: 1px solid #F0641E;
    background-color: #fff;
}

.vehicle .circle-item div {
    display: flex;
    flex-direction: column;
}

.vehicle .circle-item div span {
    color: #F0641E;
    font-family: 'Gilroy';
    font-size: 24px;
    font-weight: 700;
    text-align: end;
}

.vehicle .circle-item div p {
    color: #333;
    font-family: 'Gilroy';
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 0;
    max-width: 120px;
    text-align: end;
}

@media (max-width: 576px) {
    .vehicle {
        display: block;
    }
}