.ant-modal-content {
    padding: 0 !important;
}

.ant-btn {
    background-color: #f0641e;
    padding: 0;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: unset;
}

.ant-btn:first-child {
    position: absolute;
    left: -50px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
}

.ant-btn:last-child {
    position: absolute;
    right: -50px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
}

.ant-btn:last-child svg {
    transform: rotate(180deg);
}

.slick-slide div {
    border-radius: 20px;
}

.panorama-slide {
    height: 300px;
    border-radius: 20px;
}

.panorama-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 660px) {
    .ant-btn {
        width: 30px;
        height: 30px;
    }

    .ant-btn:first-child {
        left: 10px;
    }

    .ant-btn:last-child {
        right: 10px;
    }
}
