#location .inner {
    position: relative;
}

#location .top-img {
    position: relative;
    width: calc(100% + 40px);
    right: 40px;
    border-radius: 0 30px 30px 0;
}

#location .circle {
    width: 40%;
    position: absolute;
    left: -30%;
    top: 50%;
}

#location .text {
    position: absolute;
    z-index: 1;
    bottom: 70px;
    text-transform: uppercase;
}

#location .text {
    font-size: 1em;
    color: white;
}

#location .text h2 {
    font-size: 5em;
    color: var(--unnamed, #fff);
    font-family: Codec Pro;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
}

#location .text span {
    font-size: 0.5em;
    color: white;
    font-weight: 600;
    display: block;
}

#location .text h3 {
    font-size: 2.5em;
    font-weight: 400;
    color: var(--unnamed, #fff);
    font-family: "Codec Pro";
    font-weight: 400;
    line-height: 112.5%;
    letter-spacing: -0.72px;
}

#location .text2 {
    width: 50%;
    margin-left: 50%;
    margin-top: 120px;
    /* display: flex; */
    /* flex-direction: column; */
}

#location .line {
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #f0641e;
    top: 30%;
    z-index: -10;
}

#location .car {
    margin-top: 80px;
    height: 300px;
    position: relative;
    width: calc(100% + 80px);
    left: -40px;
    top: 50%;
    overflow: hidden;
}

#location .car-img {
    width: 300px;
    position: absolute;
    left: 0;
    top: 11%;
    z-index: 2;
}

#location .car-img img {
    width: 100%;
}

#location .lcircle {
    position: absolute;
    top: calc(30% - 13px);
    max-width: 8%;
    left: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: -1;
}

#location .cir {
    width: 26px;
    height: 26px;
    border: 2px solid #f0641e;
    background-color: white;
    border-radius: 100%;
    align-self: center;
}

#location .lcircle span {
    color: #f0641e;
    font-family: "Gilroy bold";
    font-size: 2.2em;
    font-style: normal;
    line-height: 110%;
    padding-top: 40px;
    padding-bottom: 6px;
}

#location .lcircle p {
    color: var(--unnamed, #333);
    font-size: 0.9em;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
}

#location .lcircle:nth-child(2) {
    left: 16%;
}

#location .lcircle:nth-child(4) {
    left: 21%;
}

#location .lcircle:nth-child(5) {
    left: 37%;
}

#location .lcircle:nth-child(6) {
    left: 53%;
}

#location .lcircle:nth-child(7) {
    left: 69%;
}

#location .lcircle:nth-child(8) {
    left: 85%;
}

#location .map {
    display: flex;
    align-items: flex-end;
    gap: 50px;
}

#location .map-svg {
    width: calc(77% - 50px);
    border: 1px solid #a8a8a8;
    border-radius: 20px;
    overflow: hidden;
}

#location .left {
    width: 23%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

#location .left .map-svg {
    display: none;
}

#location .carousel img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
}

#location .zoom {
    margin-top: 65px;
    position: relative;
}

#location .zoom-img {
    width: 100%;
    border-radius: 20px;
}

#location .zoom-btn {
    cursor: pointer;
    position: absolute;
    min-width: 34px;
    min-height: 34px;
    border-radius: 100%;
    width: 3%;
    height: 6.5%;
    background-color: #f0641e;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
}

#location .zoom-btn:hover {
    transform: scale(1.1);
    background-color: #f0641e7c;
}

#location .zoom-svg {
    width: 40%;
    transition: 0.5s;
}

#location .zoom-btn:hover .zoom-svg {
    transform: scale(0.8);
}

#location .zoom-btn.one {
    top: 51%;
    left: 25.5%;
}

#location .zoom-btn.two {
    left: 45%;
    top: 10%;
}

#location .zoom-btn.three {
    left: 58%;
    top: 48%;
}

#location .zoom-btn.four {
    left: 80%;
    top: 78%;
}

#location .lwrapper {
    display: flex;
}

#location .car-con .text2 p {
    font-family: "Gilroy";
    font-weight: 600;
    line-height: 140%; /* 11px */
    text-transform: uppercase;
    margin-bottom: 0.521vw;
    padding-bottom: 0;
}

#location .car-con .text2 h2 {
    color: var(--, #333);
    font-family: "Gilroy-Bold", sans-serif;
    font-size: 1.875vw;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 39.6px */
    text-transform: uppercase;
    margin-bottom: 0.521vw;
    padding-bottom: 0;
}

@media (min-width: 1920px) {
    #location .line {
        top: 4.6vw;
    }

    #location .lcircle {
        top: calc(4.6vw - 0.725vw);
    }

    #location .cir {
        width: 1.6vw;
        height: 1.6vw;
    }

    #location .car-img {
        width: 15.625vw;
        top: 1.45vw;
    }

    #location .car {
        margin-top: 4.2vw;
        height: 20vw;
    }

    #location .inner {
        margin-bottom: 4vw;
    }
}

@media (max-width: 992px) {
    #location {
        overflow: hidden;
    }

    #location .text2 {
        margin: 80px auto 0;
        width: 90%;
    }

    #location .text2 p {
        text-align: center;
        font-size: 16px;
        font-weight: 600;
    }

    #location .text2 h2 {
        text-align: center;
        font-size: 28px;
    }

    #location .map {
        flex-wrap: wrap;
    }

    #location .left {
        width: 100%;
    }

    #location .left .map-svg {
        display: block;
        width: 100%;
    }

    #location .map-svg {
        display: none;
    }

    #location .left p {
        font-size: 16px;
        text-align: center;
        font-weight: 600;
    }
    #location .left button {
        font-size: 16px;
        width: fit-content;
        margin: 0 auto;
    }

    #location .left h2 {
        font-size: 24px;
        font-weight: 600;
        text-align: center;
    }

    #location .left .ant-carousel {
        display: none;
    }
}

@media (max-width: 576px) {
    #location .car {
        display: none;
    }

    #location .text {
        bottom: 20px;
    }

    #location .car-con .text2 p {
        font-size: 16px;
        margin-bottom: 10px;
    }

    #location .car-con .text2 h2 {
        font-size: 24px;
        margin-bottom: 10px;
    }
}
