.form-con {
    width: 560px;
    background-color: #fff;
}

.form-flex {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}

.form-con p,
.form-con h2 {
    text-align: center;
}

.form-con img {
    width: 100%;
}

.form-con > .text {
    display: flex;
    flex-direction: column;
}

.form-con label {
    font-family: "K_Gotham Pro";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 120%;
    text-transform: lowercase;
    color: #f6f6f6;
    opacity: 0.5;
}

.form-con input[type="text"] {
    border: none;
    outline: none;
    background-color: transparent;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    text-transform: capitalize;
    border-bottom: 0.5px solid #f6f6f6;
    border-radius: 0;
    margin-top: 5px;
    width: 100%;
    border-bottom: 1px solid #bdbdbd;
    font-size: 0.7em;
    padding: 10px 0;
    font-family: "Codec Pro";
}

.form-con button {
    border-radius: 79px;
    font-family: "Gilroy";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    padding: 15px 35px;
    background: #fff;
    border: 1px solid rgb(255, 119, 119);
    color: rgb(255, 119, 119);
    width: 100%;
    transition: all 0.5s ease;
}

.form-con button:hover {
    color: #fff;
    background-color: #f0641e !important;
}

.form-con button:disabled {
    color: #fff;
    background-color: #aeaeae;
    border-color: #aeaeae;
    cursor: not-allowed;
}

.form-con label > input[type="checkbox"] {
    display: none;
}

.form-con label > input[type="checkbox"] + div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.form-con label > input[type="checkbox"] + div::before {
    content: "";
    display: inline-block;
    vertical-align: bottom;
    width: 14px;
    height: 14px;
    border-radius: 10%;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(255, 255, 255);
}

.form-con label > input[type="checkbox"]:checked + *::before {
    content: "✓";
    color: white;
    text-align: center;
}

.form-con input[type="text"]::placeholder {
    text-transform: none;
    font-weight: 300;
    color: #bdbdbd;
    opacity: 0.5;
}

.form-con .arrow {
    margin-left: 10px;
}

.form-con .error {
    height: 15px;
    color: rgb(255, 119, 119);
    font-size: 11px;
    margin-top: 4px;
    opacity: 0;
    transition: 0.3s;
}

.form-con .error.active {
    opacity: 1;
}

.form-con .inner {
    width: 100%;
    padding: 40px 45px;
    display: flex;
    flex-direction: column;
    /* gap: 25px; */

    align-items: stretch;
}

.form-con form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

@media screen and (max-width: 768px) {
    .form-con {
        width: 100%;
        margin: 20px;
    }

    .form-con h2 {
        font-size: 24px;
    }
}

@media (max-width: 992px) {
    .form-con p {
        font-size: 16px;
    }
    .form-con h2 {
        font-size: 24px;
    }

    .form-con input[type="text"] {
        font-size: 14px;
    }

    .form-con .inner {
        width: 100%;
        padding: 40px 30px;
    }
}

@media (min-width: 1920px) {
    .form-con {
        width: 30vw;
    }

    .form-con .inner {
        padding: 2.025vw 2.325vw;
    }

    .form-con p,
    .form-con h2 {
        padding-bottom: 0.525vw;
    }

    .form-con form {
        gap: 0.825vw;
    }

    .form-con input[type="text"] {
        padding: 0.725vw 0;
    }

    .form-con button {
        font-size: 0.95vw;
        padding: 0.8vw 1.7vw;
    }
}
